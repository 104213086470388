import MultifactorAuth from 'supertokens-web-js/recipe/multifactorauth';
import Session from 'supertokens-web-js/recipe/session';

import { frontendControlErrorMessage } from '@/components/auth/constants';

import { AuthActionError } from '../error';

export async function shouldShowTotp() {
  if (!(await Session.doesSessionExist())) {
    throw new AuthActionError(
      'Illegal function call: For first factor setup, you do not need to call this function'
    );
  }

  try {
    const mfaInfo = await MultifactorAuth.resyncSessionAndFetchMFAInfo();
    const isTotpSetup = mfaInfo.factors.alreadySetup.includes('totp');
    const next = mfaInfo.factors.next;
    return (next.includes('totp') && isTotpSetup) ?? false;
  } catch (err: any) {
    if (err.isSuperTokensGeneralError === true) {
      throw new AuthActionError(frontendControlErrorMessage(err.message));
    }
    throw new AuthActionError('Oops! Something went wrong.');
  }
}
