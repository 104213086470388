import { useRouter, useSearchParams } from 'next/navigation';

import { shouldShowTotp } from '@/api/auth/mfa/shouldShowTotp';
import { totpEnterUrl } from '@/lib/urls';

export function useRedirectAfterAuthentication() {
  const defaultNextUrl = process.env.NEXT_PUBLIC_ROLLBAR_BASE_URL ?? '/';
  const router = useRouter();
  const searchParams = useSearchParams();
  const explicitNextUrl = searchParams?.get('next_url');

  return async (response: { status: 'OK'; nextUrl?: string }) => {
    const postLoginUrl = explicitNextUrl ?? response.nextUrl ?? defaultNextUrl;

    const nextUrl = (await shouldShowTotp())
      ? totpEnterUrl(postLoginUrl)
      : postLoginUrl;

    router.push(nextUrl);
  };
}
